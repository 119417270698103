import React, { useEffect, useState } from 'react'
import Layout from '../Layout'
import {useParams} from 'react-router-dom';
import axios from 'axios';
import EntryForm from '../EntryForm';

export default function Edit() {

    let {encId} = useParams();
    let id = atob(encId);
    const [details, setDetails] = useState(false);

    useEffect(()=>{
        axios.get('/trans-detail/'+id)
        .then(response=>{
            //console.log("response", response);
            if(response?.data?.type){
                if(response.data.type=='success'){
                    setDetails(response.data.message);
                }
                if(response.data.type=='error'){
                    
                }
            }
        })
        .catch(error=>console.log(error));
    }, []);

    return (
            (details) && 
            <EntryForm {...details}/>
    )
}
