import React, {useRef, useState, useEffect} from 'react'
import Layout from './Layout'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useNavigate } from 'react-router-dom';
import { validateData } from '../helpers';
import axios from 'axios';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function EntryForm(props) {

    const location = useLocation();
    const navigate = useNavigate();
    const path = ((props.trans_type) ? '/'+props.trans_type : location.pathname);
    const colorClass = ((path=='/credit') ? 'red' : ((path=='/debit') ? 'green' : ''));
    const transType = ((path=='/credit') ? 'credit' : 'debit');
    const entryUrl = ((props.trans_type) ? '/update-transaction/'+props.id : "/add-transaction");
    const detailRef = useRef(null);
    const [amount, setAmount] = useState(props.amount)
    const [errors, setErrors] = useState([])
    const [startDate, setStartDate] = useState((props.date) ? new Date(props.date) : new Date());
    const [apiErrors, setApiErrors] = useState([])
    const [btnDisabled, setBtnDisabled] = useState(false)
    const [success, setSuccess] = useState(false)

    const addEntry=(e)=>{
        e.preventDefault();
        setApiErrors([]);
        setErrors([]);
        setSuccess(false);
        let frmData = {
            'Amount': amount,
            'Detail': detailRef.current.value,
            'Date': moment(startDate).format("YYYY-MM-DD"),
            'transType': transType
        }
        const frmErrors = validateData(frmData);
        if(!frmErrors){
            setBtnDisabled(true);
            axios.post(entryUrl, frmData)
            .then(response=>{
                //console.log(response);
                if(response?.data?.type){
                    setBtnDisabled(false);
                    if(response.data.type=='success'){
                        document.getElementById("employerLogin").reset();
                        if(!props.amount){
                            setAmount("")
                        }
                        setSuccess(response.data.message);
                        setTimeout(() => {
                            navigate("/");
                        }, 1500);
                    }
                    if(response.data.type=='error'){
                        setApiErrors(response.data.message);
                    }
                }
            })
            .catch(error=>{
                console.log(error);
                setBtnDisabled(false);
            });
        }
        else{
            setBtnDisabled(false);
            setErrors(frmErrors);
        }
    }

    const deleteTrans=(id)=>{
        axios("/delete-trans/"+id)
        .then(response=>{
            if(response?.data?.type){
                if(response.data.type=='success'){
                    document.getElementById("employerLogin").reset();
                    toast(response.data.message, {
                        position: "bottom-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        onClose: () => navigate("/")
                    });
                    
                }
                if(response.data.type=='error'){
                    
                }
            }
        })
        .catch(error=>console.log(error))
    }

    useEffect(() => {
        if(!amount){
            setBtnDisabled(true);
        }
        else{
            setBtnDisabled(false);
        }
        return () => {
            setBtnDisabled(false);
        }
    }, [amount])

    useEffect(() => {
        if(success){
            setTimeout(function(){
                setSuccess(false)
            }, 5000);
        }
    }, [success])

    return (
<Layout>
    <div className="signup-form entry-form">
        <form id="employerLogin" action="#" method="post">
            <div className="form-group">
                <input type="text" id="amount" autoComplete="off" className="form-control" name="amount" onChange={(e)=>setAmount(e.target.value)} placeholder="Enter Amount" 
                defaultValue={amount}
                onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                            }
                        }}
                />
                <label className="error">{errors.Amount}</label>
            </div>
            <div className="form-group">
                <input type="text" ref={detailRef} id="detail" autoComplete="off" className="form-control" name="detail" placeholder="Enter Details" defaultValue={props.detail}/>
                <label className="error">{errors.Detail}</label>
            </div>
            <div className="form-group">
                <DatePicker selected={ startDate } dateFormat="dd-MMM-yyyy" onChange={(date) => setStartDate(date)} className='form-control'/>
                <label className="error">{errors.Date}</label>
            </div> 
            <div className="form-group">
                <button disabled={ btnDisabled ? 'disabled' : '' } type="submit" className={ "btn btn-"+ colorClass +" btn-lg btn-block form-control" } onClick={(e)=>addEntry(e)}>{ props.amount ? 'Update' : 'Save' }</button>
            </div>
            <ul className="errorList">
            {
                apiErrors.map((error,key)=><li key={key}>{error}</li>)
            }
            </ul>
            {
                success &&
                <div className="alert alert-info" role="alert">
                    Your transction has been saved!!
                </div>
            }
        </form>
    </div>
    {
        (props.amount) &&
        <div className='row fixed-bottom marginBottom'>
            <div className='col-lg-6 col-12'>
                <button type="button" className="btn btn-warning largeBtn btnCenter" onClick={()=>deleteTrans(props.id)}>Delete Transaction</button>
            </div>
        </div>
    }
    <ToastContainer />
</Layout>
    )
}
