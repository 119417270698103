import axios from 'axios'
import React, {useEffect, useState} from 'react'
import { useSelector } from 'react-redux';
import Entry from './Entry';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import DatePicker from "react-datepicker";
import moment from 'moment';

export default function Entries() {

    const [entryData, setEntryData] = useState([]);
    const [todalCredit, setTodalCredit] = useState("");
    const [todalDebit, setTodalDebit] = useState("");
    const date = new Date(), y = date.getFullYear(), m = date.getMonth();
    const firstDay = new Date(y, m, 1);
    const lastDay = new Date(y, m + 1, 0);
    const [startDate, setStartDate] = useState(firstDay)
    const [endDate, setEndDate] = useState(lastDay)
    const [loading, setLoading] = useState(false)
    const store = useSelector(state=>state);

    const getEntries =()=>{
        setEntryData([])
        setTodalCredit("")
        setTodalDebit("")
        setLoading(true)
        if(store?.user?.id){
            axios.post("/get-entries", {
                stateDate: moment(startDate).format('YYYY-MM-DD'),
                endDate: moment(endDate).format('YYYY-MM-DD')
            })
            .then(response=>{
                //console.log("response", response);
                if(response?.data?.type){
                    if(response.data.type=='success'){
                        setLoading(false)
                        setEntryData(response.data.message);
                    }
                    if(response.data.type=='error'){
                        setLoading(false)
                    }
                }
            })
            .catch(error=>console.log(error));
        }
    }

    useEffect(() => {
        getEntries();
    }, [store.user])

    useEffect(() => {
        let creditSum = 0;
        let debitSum = 0;
        if(entryData && entryData.length>0){
            entryData.forEach(function(edata, ekey){
                if(edata.trans_type==="credit"){
                    creditSum = Number(edata.amount) + creditSum;
                }
                else{
                    debitSum = Number(edata.amount) + debitSum;
                }
            })
            setTodalCredit(creditSum)
            setTodalDebit(debitSum)
        }
    }, [entryData])

    return (
        <>
        <div className='row'>
            <div className='col-lg-2 col-4 entryHead dateFilter'>
                <DatePicker selected={ startDate } dateFormat="dd-MMM-yyyy" onChange={(date) => setStartDate(date)} className='form-control'/>
            </div>
            <div className='col-lg-2 col-4 entryHead dateFilter'>
                <DatePicker selected={ endDate } dateFormat="dd-MMM-yyyy" onChange={(date) => setEndDate(date)} className='form-control'/>
            </div>
            <div className='col-lg-2 col-4 entryHead'>
                <button type="button" className="btn btn-warning largeBtn btnCenter" onClick={()=>getEntries()}>Filter</button>
            </div>
        </div> 
        <div className='row'>
            <div className='col-lg-2 col-4 entryHead whiteHead'>Total</div>
            <div className='col-lg-2 col-4 entryHead whiteHead moneyTxtBold textRed'>{ "₹ "+todalCredit}</div>
            <div className='col-lg-2 col-4 entryHead whiteHead moneyTxtBold textGreen'>{ "₹ "+todalDebit}</div>
        </div>       
        <div className='row'>
            <div className='col-lg-2 col-4 entryHead'>Entries</div>
            <div className='col-lg-2 col-4 entryHead'>You Gave</div>
            <div className='col-lg-2 col-4 entryHead'>You Got</div>
        </div>
        
        {
            
            (!loading) ?
            (
                (entryData.length > 0) ?
                entryData.map((data, key)=>{
                    return <Entry {...data} key={key}/>
                })
                :
                <div className='row'>&nbsp;&nbsp;No Record Found..</div>
            )
            :
            <Skeleton count={10} /> 
        }
        </>
    )
}
