import axios from 'axios';
import React from 'react'
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Navigation() {

    const location = useLocation();
    const navigate = useNavigate();
    const path = location.pathname;
    const colorClass = ((path=='/credit') ? 'red' : ((path=='/debit') ? 'green' : ''));
    const store = useSelector(state=>state);

    const logOut=()=>{
        axios.get("/logout")
        .then(response=>{
            console.log(response)
            if(response?.data?.type){
                if(response.data.type=='success'){
                    localStorage.removeItem("user");
                    toast(response.data.message, {
                        position: "bottom-right",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        onClose: () => window.location.href="/login"
                    });
                    
                }
                if(response.data.type=='error'){
                    
                }
            }
        })
        .catch(error=>console.log(error))
    }

    return (
        <>
            <div className='row fixed-top marginBottom bottomGrey fixedNav' onClick={()=>navigate('/')}>
                <div className='col-lg-2 col-2'>
                    {
                        (path!=='/') ?
                        <div className={ "backIcon "+ colorClass } onClick={()=>navigate(-1)}>
                        <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"></path></svg>
                        </div>
                        :
                        <span>&nbsp;</span>
                    }
                </div>
                <div className={ 'col-lg-2 col-7 siteTitle '+ colorClass}>
                    <div className={ "roundLogo "+ colorClass}>H</div>
                    <div className="titleTxt">Hisab Khata</div>
                </div>
                {
                    (store?.user?.id) &&
                    <div className={ 'col-lg-2 col-2'}>
                        <div className="logoutBtn pullRight" onClick={()=>logOut()}>Logout</div>
                    </div>
                }
            </div>
            <ToastContainer />
        </>
    )
}
