import React from 'react'
import Navigation from './partials/Navigation'
import { useCheckUserIfNotInStore } from '../helpers';

export default function Layout({children}) {

    useCheckUserIfNotInStore();

    return (
        <>
<div className="container-fluid mainBodyContainer">
<div className="col-md-12">
<Navigation />
<div className='startBody'>&nbsp;</div>
{children}
</div>
</div>
        </>
    )
}
