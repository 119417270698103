import React from 'react';
import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter as Router, Routes, Route, Navigate, Outlet} from 'react-router-dom'
import Login from './components/Login';
import SignUp from './components/SignUp';
import Home from './components/Home';
import PageNotFound404 from './components/PageNotFound404';
import EntryForm from './components/EntryForm';
import Detail from './components/partials/Detail';
import { useSelector } from 'react-redux';
import Edit from './components/partials/Edit';

function App() {

  const store = useSelector(state=>state);

  function PrivateOutlet() {
    const isLogin = (store?.user?.id);
    return isLogin ? <Outlet /> : <Navigate to="/login" />;
  }

  function PrivateOutlet2() {
    const isLogin = (store?.user?.id);
    return !isLogin ? <Outlet /> : <Navigate to="/" />;
  }

  return (
    <>
    <Router>
      <Routes>
        <Route path="/login" element={<PrivateOutlet2 />}>
          <Route path="/login" element={<Login/>} />
        </Route>
        <Route path="/signup" element={<PrivateOutlet2 />}>
          <Route path="/signup" element={<SignUp/>} />
        </Route>
        <Route exact path="/" element={<Home />} />
        <Route path="/debit" element={<EntryForm />} />
        <Route path="/credit" element={<EntryForm />} />
        <Route path="/detail/:encId" element={<Detail />} />
        <Route path="/edit/:encId" element={<Edit />} />
        <Route path="*" element={<PageNotFound404 />} />
      </Routes>
    </Router>
    </>
  );
}

export default App;
