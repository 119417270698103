import React, { useState } from 'react'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

export default function Entry(props) {
    const navigate = useNavigate();
    return (
        <>
        <div className='row' onClick={()=>navigate('/detail/'+btoa(props.id))}>
            <div className='col-lg-2 col-4 dateTxt entryRow'>{ moment(props.date).format('DD MMM YY') }</div>
            <div className='col-lg-2 col-4 moneyTxt textRed entryRow'>
                { (props.trans_type==="credit") && "₹ "+props.amount }
                {
                    (props.trans_type==="credit") &&
                    <div className='transDetail'>{props.detail}</div>
                }
            </div>
            <div className='col-lg-2 col-4 moneyTxt textGreen entryRow'>
                { (props.trans_type==="debit") && "₹ "+props.amount }
                {
                    (props.trans_type==="debit") &&
                    <div className='transDetail'>{props.detail}</div>
                }
            </div>
        </div>
        </>
    )
}
