import React from 'react'
import Footer from './partials/Footer';
import Layout from './Layout';
import Entries from './partials/Entries';

export default function Home() {

    return (
        <Layout>
            <Entries />
            <Footer />
        </Layout>
    )
}
