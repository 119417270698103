import React, { useEffect, useState } from 'react'
import Layout from '../Layout'
import {useParams} from 'react-router-dom';
import axios from 'axios';
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

export default function Detail() {

    const navigate = useNavigate();
    let {encId} = useParams();
    let id = atob(encId);
    const [details, setDetails] = useState(false);

    useEffect(()=>{
        axios.get('/trans-detail/'+id)
        .then(response=>{
            //console.log("response", response);
            if(response?.data?.type){
                if(response.data.type=='success'){
                    setDetails(response.data.message);
                }
                if(response.data.type=='error'){
                    
                }
            }
        })
        .catch(error=>console.log(error));
    }, []);

    return (
            (details) && 
            <Layout>
                <div className='detailofTrans'>
                    <div className='row detailRow'>
                        <div className='col-lg-2 col-4'>Amount :</div>
                        <div className='col-lg-4 col-8'>{details.amount}</div>
                    </div>
                    <div className='row detailRow'>
                        <div className='col-lg-2 col-4'>Detail :</div>
                        <div className='col-lg-4 col-8'>{details.detail}</div>
                    </div>
                    <div className='row detailRow'>
                        <div className='col-lg-2 col-4'>Date :</div>
                        <div className='col-lg-4 col-8'>{ moment(details.date).format('DD MMM YYYY') }</div>
                    </div>
                </div>
                <div className='row fixed-bottom marginBottom'>
                <div className='col-lg-6 col-12'>
                <button type="button" className="btn btn-warning largeBtn btnCenter" onClick={()=>navigate('/edit/'+btoa(details.id))}>Edit</button>
                </div>
                </div>
            </Layout>
    )
}
