import axios from 'axios';
import React, {useRef, useState} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAddDataToStore, validateData } from '../helpers';
import Layout from './Layout'
import LoginLayout from './LoginLayout';

export default function SignUp() {

    const navigate = useNavigate();
    const {addUserData} = useAddDataToStore();
    const nameRef = useRef(null);
    const emailRef = useRef(null);
    const passwordRef = useRef(null);
    const [btnDisabled, setBtnDisabled] = useState(false)
    const [errors, setErrors] = useState([])
    const [apiErrors, setApiErrors] = useState([])
    // const [successMsg, setSuccessMsg] = useState('')

    const SignUp = (e)=>{
        e.preventDefault();
        setApiErrors([]);
        setErrors([]);
        //setSuccessMsg('');
        let frmData = {
            name: nameRef.current.value,
            email: emailRef.current.value,
            password: passwordRef.current.value
        }
        const frmErrors = validateData(frmData);
        if(!frmErrors){
            setBtnDisabled(true);
            axios.post("/register", frmData)
            .then(response=>{
                //console.log(response);
                if(response?.data?.type){
                    setBtnDisabled(false);
                    if(response.data.type=='success'){
                        localStorage.setItem('user', JSON.stringify(response.data.user.token));
                        addUserData(response.data.user);
                        document.getElementById("employerLogin").reset();
                        navigate("/");
                    }
                    if(response.data.type=='error'){
                        setApiErrors(response.data.message);
                    }
                }
            })
            .catch(error=>{
                console.log(error);
                setBtnDisabled(false);
            });
        }
        else{
            setBtnDisabled(false);
            setErrors(frmErrors);
        }
    }

    return (
        <>
<Layout>
<div className="signup-form">
    <form id="employerLogin" action="#" method="post">
		<p className="hint-text">Signup For a new account</p>
        <div className="form-group">
        	<input type="text" ref={nameRef} id="name" className="form-control" name="name" placeholder="Name"/>
            <label className="error">{errors.name}</label>
        </div>
        <div className="form-group">
        	<input type="email" ref={emailRef} id="email" className="form-control" name="email" placeholder="Email"/>
            <label className="error">{errors.email}</label>
        </div>
		<div className="form-group">
            <input type="password" ref={passwordRef} id="password" className="form-control" name="password" placeholder="Password" />
            <label className="error">{errors.password}</label>
        </div> 
		<div className="form-group">
            <button disabled={ btnDisabled ? 'disabled' : '' } type="submit" className="btn btn-blue btn-lg btn-block" onClick={(e)=>SignUp(e)}>Signup Now</button>
        </div>
        <ul className="errorList">
        {
            apiErrors.map((error,key)=><li key={key}>{error}</li>)
        }
        </ul>
    </form>
    <div className="form-group">
        <Link to="/login" className="forgetPassword">Already Signup? Login Here</Link>
    </div>
    <div className="form-group">
        <Link to="/forgetpassword" className="forgetPassword">Forget Password?</Link>
    </div>
</div>
</Layout>
        </>
    )
}
