import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function Footer() {
    const navigate = useNavigate();
    return (
        <>
        <div className='row fixed-bottom paddingBottom footerBottom'>
            <div className='col-lg-3 col-6'>
                <button type="button" className="btn btn-danger largeBtn btnCenter" onClick={()=>navigate('/credit')}>You Gave</button>
            </div>
            <div className='col-lg-3 col-6'>
                <button type="button" className="btn btn-success largeBtn btnCenter" onClick={()=>navigate('/debit')}>You Got</button>
            </div>
        </div>
        </>
    )
}
